// Local storage
export const LOCAL_STORAGE_KEY = {
  LANG: "Language",
  TIME_ZONE: "TimeZone"
} as const;

export const SESSION_STORAGE_KEY = {
  ACTIVE_DASHBOARD_ID: "ActiveDashboardID",
  SAVE_DEVICE_LOGIN_INFO: "SaveDeviceLoginInfo"
} as const;

export const THEME_TYPE = {
  DARK: "Dark",
  LIGHT: "Light"
} as const;

export const COLOR_MODE = { ...THEME_TYPE, SYSTEM: "System" as const };

export const LANG = {
  EN: "En",
  KO: "Ko"
} as const;

// Max length
export const MAX_LENGTH = {
  USER_ID: 30,
  USER_NAME: 31,
  PHONE_NUMBER: 34,
  MEMO: 1000
} as const;

// Cookie
export const USER_INFO = "UserInfo";
export const ACCESS_TOKEN = "AccessToken";
export const REFRESH_TOKEN = "RefreshToken";

// Grid
export const GRID_UNIT = 5;
export const GRID_GAP = 20;
export const WIDTH_PER_GRID_UNIT = 284;
export const GRID_BREAKPOINT_TYPE = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  XXL: "xxl"
} as const;

// Dashboard
export const MAX_DASHBOARD_COUNT = 8;
export const MIN_DASHBOARD_SEQUENCE_CONVERSION_TIME = 5;
export const MAX_DASHBOARD_SEQUENCE_CONVERSION_TIME = 59;
export const DEFAULT_DASHBOARD_SEQUENCE_CONVERSION_TIME = 20;

// Widget
export const MAX_LENGTH_WIDGET_NAME = 50;
export const MIN_LENGTH_DASHBOARD_NAME = 1;
export const MAX_LENGTH_DASHBOARD_NAME = 50;
export const MAX_WIDGET_COUNT_ON_DASHBOARD = 30;
export const CHART_TOOLTIP_MARKER_SIZE = "6px";
export const ECHART_LEGEND_SIZE = 4;
export const ECHART_SERIES_SYMBOL_SIZE = 5;
export const ECHART_VEHICLE_STATISTICS_SIZE = 5;
export const DEFAULT_WIDGET_MAIN_PROPORTION = 50;

export const WIDGET_TYPE = {
  CUMULATE_PEOPLECOUNTS: "CUMULATE_PEOPLECOUNTS",
  CROWD_SAFETY_STATISTICS: "CROWD_SAFETY_STATISTICS",
  STAYING_PEOPLECOUNTS: "STAYING_PEOPLECOUNTS",
  TYPE_PEOPLECOUNTS: "TYPE_PEOPLECOUNTS",
  ENTRY_EXIT_PEOPLECOUNTS: "ENTRY_EXIT_PEOPLECOUNTS",
  RANK_PEOPLECOUNTS: "RANK_PEOPLECOUNTS",
  AREA_TRAFFIC: "AREA_TRAFFIC",
  NUMBER_OF_PEOPLE_BY_AREA: "NUMBER_OF_PEOPLE_BY_AREA",
  OPERATOR_MAP: "OPERATOR_MAP",
  CUMULATIVE_SALES_POS: "CUMULATIVE_SALES_POS",
  REVENUE_PER_SALES_POS: "REVENUE_PER_SALES_POS",
  SALES_TRANSACTION_POS: "SALES_TRANSACTION_POS",
  CHECKOUT_QUEUE: "CHECKOUT_QUEUE",
  STATUS_OF_ENTER_EXIT: "STATUS_OF_ENTER_EXIT",
  DEFAULT_EMPTY: "DEFAULT_EMPTY",
  DOCK_MANAGEMENT: "DOCK_MANAGEMENT",
  VEHICLE_IN_OUT_EVENT: "VEHICLE_IN_OUT_EVENT",
  VEHICLE_IN_OUT_STATISTICS: "VEHICLE_IN_OUT_STATISTICS",
  ILLEGAL_PARKING: "ILLEGAL_PARKING",
  SLIP_AND_FALL: "SLIP_AND_FALL",
  MES_MANAGEMENT: "MES_MANAGEMENT",
  TEMPERATURE_EVENT: "TEMPERATURE_EVENT",
  TEMPERATURE_STATISTICS: "TEMPERATURE_STATISTICS",
  STOPPED_VEHICLE_STATISTICS: "STOPPED_VEHICLE_STATISTICS",
  STOPPED_VEHICLE_EVENT: "STOPPED_VEHICLE_EVENT",
  TRAFFIC_JAM_EVENT: "TRAFFIC_JAM_EVENT",
  TRAFFIC_JAM_STATISTICS: "TRAFFIC_JAM_STATISTICS",
  CUMULATE_VEHICLECOUNTS: "CUMULATE_VEHICLECOUNTS",
  FORKLIFT_SPEED_EVENT: "FORKLIFT_SPEED_EVENT",
  CUMULATE_SHOPPING_CART_COUNTS: "CUMULATE_SHOPPING_CART_COUNTS",
  // Heatmap
  COMMON_HEATMAP: "COMMON_HEATMAP",
  HEATMAP_TODAY: "HEATMAP_TODAY_MODE",
  HEATMAP_SEARCH: "HEATMAP_SEARCH_MAP",
  HEATMAP_TIME_LAPSE: "HEATMAP_TIME_LAPSE_MAP",
  // Map
  RETAIL_MAP: "RETAIL_MAP",
  FACTORY_MAP: "FACTORY_MAP",
  CITY_MAP: "CITY_MAP"
} as const;

export const WIDGET_STYLE_TYPE = {
  MAIN: "MAIN",
  EXPANSION: "EXPANSION",
  MAP: "MAP"
} as const;

export const WIDGET_MAIN_TYPE = {
  SUMMARY: "SUMMARY",
  CHART: "CHART",
  LIST: "LIST",
  LIST_BLOCK: "LIST_BLOCK",
  MAP: "MAP"
} as const;

export const WIDGET_EXPANSION_TYPE = {
  CHART: "CHART",
  LIST: "LIST",
  THUMBNAIL: "THUMBNAIL"
} as const;

export const CHART_TYPE = {
  // BAR
  BAR: "BAR",
  BAR_LINE: "BAR_LINE",
  BAR_NEGATIVE: "BAR_NEGATIVE",
  BAR_CATEGORY: "BAR_CATEGORY",
  BAR_CATEGORY_STACK: "BAR_CATEGORY_STACK",
  BAR_HORIZONTAL: "BAR_HORIZONTAL",

  // LINE
  LINE: "LINE",
  LINE_SECTION: "LINE_SECTION",
  LINE_AREA: "LINE_AREA",
  LINE_AREA_SECTION: "LINE_AREA_SECTION",
  LINE_AREA_NEGATIVE: "LINE_AREA_NEGATIVE",

  // RADAR
  RADAR: "RADAR",

  // PIE
  PIE: "PIE",
  PIE_DOUGHNUT: "PIE_DOUGHNUT",
  PIE_DOUGHNUT_THIN: "PIE_DOUGHNUT_THIN",

  // SCATTER
  SCATTER: "SCATTER",
  SCATTER_EFFECT: "SCATTER_EFFECT",

  // HEATMAP
  HEATMAP: "HEATMAP",
  HEATMAP_CIRCLE: "HEATMAP_CIRCLE",

  // GAUGE
  GAUGE: "GAUGE",
  GAUGE_BEELINE: "GAUGE_STRAIGHT",
  GAUGE_BEELINE_SOLID: "GAUGE_STRAIGHT_SOLID",

  // SANKEY
  SANKEY: "SANKEY"
} as const;

// Format
export const FORMAT = {
  TIME: "HH:mm:ss",
  DATE: "MM-DD-YYYY",
  DATE2: "MM/DD/YYYY",
  DATE3: "MM/DD",
  DATE4: "DD/MM/YYYY",
  DATE_MONTH: "YYYY-MMMM-DD",
  DATE_MONTH_TYPE: "MM/DD/YYYY",
  DATE_TIME_12H: "MM/DD/YYYY A hh:mm",
  DATE_TIME_12H_FULL: "MM/DD/YYYY A hh:mm:ss",
  DATE_TIME_24H: "MM/DD/YYYY HH:mm",
  DATE_TIME2_24H: "MM/DD HH:mm",
  TIME_12H: "A hh:mm:ss",
  DATE_TIME_ISO: "YYYY-MM-DD[T]HH:mm:ssZ"
} as const;

export const MONTH = {
  JAN: "January",
  FEB: "February",
  MAR: "March",
  APR: "April",
  MAY: "May",
  JUN: "June",
  JUL: "July",
  AUG: "August",
  SEP: "September",
  OCT: "October",
  NOV: "November",
  DEC: "December"
} as const;

export const COMPARISON_TYPE = {
  INCREASE: "INCREASE",
  DECREASE: "DECREASE",
  EQUAL: "EQUAL"
} as const;

export const HEATMAP_MODE = {
  TODAY: "TODAY",
  SEARCH: "SEARCH",
  TIME_LAPSE: "TIME_LAPSE"
} as const;

export const COMPARISON_DATE_TYPE = {
  NONE: "NONE",
  YESTERDAY: "YESTERDAY",
  A_WEEK_AGO: "A_WEEK_AGO",
  A_MONTH_AGO: "A_MONTH_AGO",
  DATE: "DATE"
} as const;

export const TABLE_TYPE = {
  DEFAULT: "DEFAULT",
  BORDERED: "BORDERED",
  HEAD: "HEAD",
  HAS_CHECKBOX: "HAS_CHECKBOX"
} as const;

export const HEATMAP_PLAY_SPEED = {
  SLOW: "SLOW",
  NORMAL: "NORMAL",
  FAST: "FAST"
} as const;

export const COUNTING_DIRECTION = {
  IN: "IN",
  OUT: "OUT"
} as const;

export const DOCK_STATUS = {
  IN_USE: "In Use",
  TIME_OUT: "Time Out",
  UNUSED: "Unused"
} as const;

export const DOCK_DISPLAY_TYPE = {
  A: "A",
  B: "B"
} as const;

export const TRAFFIC_STATUS = {
  ACCIDENT: "ACCIDENT",
  SPEEDING: "SPEEDING",
  ILLEGAL: "ILLEGAL"
} as const;

export const TRAFFIC_STATUS_LABEL = {
  ACCIDENT: "Accident",
  SPEEDING: "Speeding",
  ILLEGAL: "Illegal Parking"
} as const;

export const CAMERA_STATUS_TYPE = {
  WARNING: "WARNING",
  CRITICAL: "CRITICAL",
  CLEAR: "CLEAR"
} as const;

export const TOOLTIP_PLACEMENT_TYPE = {
  TOP: "top",
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  BOTTOM: "bottom",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right"
} as const;

export const BUTTON_STYLE_TYPE = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
  FOURTH: "fourth"
} as const;

export const CALENDAR_MODE_RANGE_TYPE = {
  TODAY: "today",
  CUSTOM: "custom",
  LAST_24_HOURS: "last_24_hours",
  LAST_7_DAYS: "last_7_days",
  LAST_30_DAYS: "last_30_days",
  LAST_90_DAYS: "last_90_days",
  YESTERDAY: "yesterday",
  DAY_BEFORE_YESTERDAY: "day_before_yesterday"
} as const;

export const ALARM_BUBBLE_TYPE = {
  NO_HELMET: "No Helmet",
  DANGER: "Danger",
  RESTRICTED_AREA: "Restricted Area",
  FALL_SLIP: "Slip & Fall",
  NO_VEST: "No Vest",
  STOPPED_VEHICLE: "Stopped vehicle"
} as const;

export const WIDGET_CATEGORY = {
  ALL: "all",
  PEOPLE_MANAGEMENT: "people_management",
  SEAMLESS_OPERATION: "seamless_operation",
  FACILITY_MANAGEMENT: "facility_management",
  MANAGEMENT_MAP: "management_map",
  VEHICLE_MANAGEMENT: "vehicle_management",
  SAFETY_MANAGEMENT: "safety_management"
} as const;

export const SALES_TRANSACTION_TAB = {
  SALES: "salesTransaction",
  AVG: "averageTransactionValue",
  TRANSACTION: "transactionRate"
} as const;

export const SETUP_MENU = {
  DEVICE: "device",
  EVENT: "event",
  USER: "user",
  LOG: "log",
  SYSTEM: "system"
} as const;

export const PASSWORD_VALID_RESULT_TYPE = {
  AT_LEAST_8_CHARACTER: "at_least_8_character",
  UP_TO_64_CHARACTER: "up_to_64_character",
  MUST_HAVE_2_DIFFERENT_TYPE: "must_have_2_different_type",
  MUST_HAVE_3_DIFFERENT_TYPE: "must_have_3_different_type",
  HAS_CONSECUTIVE_CHARACTER: "has_consecutive_character",
  HAS_REPEAT_CHARACTER: "has_repeat_character",
  USER_ID_NOT_USED_AS_PASSWORD: "userId_not_used_as_password"
} as const;

export const AUTHENTICATE_INPUT = {
  ID: "id",
  PASSWORD: "password",
  SAVE_FOR_FUTURE_SEARCH: "saveForFutureSearch"
} as const;

export const ORDER_KEY = {
  NAME: "name",
  MODEL: "model",
  MAC: "mac",
  STATUS: "status",
  IP: "ip"
} as const;

export const SORT_TYPE = {
  NONE: "NONE",
  ASCENDING: "ASCENDING",
  DESCENDING: "DESCENDING"
} as const;

export const WAVE_SYNC_STATUS = {
  LINKED: "Linked",
  LINK_DISCONNECT: "Disconnect"
} as const;

export const DEVICE_STATUS_TYPE = {
  ONLINE: "online",
  OFFLINE: "offline"
} as const;

export const DEVICE_STATUS = {
  CONNECT: "Connect",
  DISCONNECT: "Disconnect"
} as const;

export const PAGINATION_DEFAULT_PER_PAGE = 50;
export const PAGE_DEFAULT = 0;
export const SCROLL_DEFAULT_PER_PAGE = 50;

export const QUERY_STRING = {
  SORT_TYPE: {
    ASCENDING: "asc",
    DESCENDING: "desc"
  }
} as const;

export const USER_NODE_TREE_TYPE = {
  GROUP: "group",
  USER: "user"
} as const;

export const VMS_TYPE = {
  WAVE: "WAVE",
  SSM: "SSM",
  NVR: "NVR"
} as const;

export const RECORDING_SOURCE = {
  NONE: "NONE",
  CAMERA: "CAMERA",
  WAVE: "WAVE",
  SSM: "SSM",
  NVR: "NVR"
} as const;

export const AI_TYPE = {
  PEOPLE_COUNT: "PEOPLE_COUNT",
  QUEUE_MANAGEMENT: "QUEUE_MANAGEMENT",
  HEATMAP: "HEATMAP",
  SLIP_AND_FALL: "SLIP_AND_FALL",
  TEMPERATURE_DETECTION: "TEMPERATURE_DETECTION",
  STOPPED_VEHICLE: "STOPPED_VEHICLE",
  TRAFFIC_JAM: "TRAFFIC_JAM",
  VEHICLE_COUNT: "VEHICLE_COUNT",
  CROWD_COUNT: "CROWD_COUNT",
  FORKLIFT_SPEED: "FORKLIFT_SPEED",
  SHOPPING_CART_COUNT: "SHOPPING_CART_COUNT"
} as const;

export const BUTTON_TYPE = {
  OK: "OK",
  APPLY: "Apply",
  CANCEL: "Cancel",
  CLOSE: "Close",
  BACK: "Back"
} as const;

export const DEVICE_CONNECTION_TEST_STATUS = {
  SUCCESS: "Success",
  AI_NOT_SUPPORTED: "AI not supported",
  AUTHENTICATION_FAILED: "Authentication failed",
  FAIL: "Fail",
  REGISTERED: "Registered"
} as const;

export const DEVICE_TYPE = {
  CAMERA: "CAMERA",
  VMS: "VMS"
} as const;

export const HEATMAP_TYPE = {
  COLOR: "COLOR",
  BW: "BW"
} as const;

export const RECEIPT_TYPE = {
  NONE: "NONE",
  REFUND: "REFUND",
  DISCOUNT: "DISCOUNT"
} as const;

export const MAP_OVERLAY_TYPE = {
  MAX: "max",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  NONE: "none"
} as const;

export const MAP_RETAIL_SUMMARY_TYPE = {
  NONE: "NONE",
  REFUND: "REFUND",
  COUPON: "COUPON"
} as const;

export const EVENT_TYPE = {
  NONE: "NONE",
  EVENT_ALL: "EVENT_ALL",
  SLIP_AND_FALL: "SLIP_AND_FALL",
  TRAFFIC_JAM: "TRAFFIC_JAM",
  QUEUE_MANAGEMENT: "QUEUE_MANAGEMENT",
  MES: "MES",
  MES_ACTION: "MES_ACTION",
  TEMPERATURE: "TEMPERATURE",
  STOPPED_VEHICLE: "STOPPED_VEHICLE",
  FORKLIFT_SPEED: "FORKLIFT_SPEED"
} as const;

export const EVENT_PLAYER_TYPE = {
  LIVE: "Live",
  PLAYBACK: "Playback"
} as const;

export const ALARM_POPUP_NOTIFICATION = {
  ON: "ON",
  OFF: "OFF"
} as const;

export const AFTER_ACTION_DURATION_TIME = {
  FIVE_MINUTES: 5,
  TEN_MINUTES: 10,
  TWENTY_MINUTES: 20,
  THIRTY_MINUTES: 30,
  FORTY_MINUTES: 40,
  FIFTY_MINUTES: 50,
  SIXTY_MINUTES: 60
} as const;

export const SLIP_AND_FALL_TYPE = {
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE"
} as const;

export const QUEUE_LEVEL_TYPE = {
  NONE: "None",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  MAX: "Max"
} as const;

export const EVENT_STATUS = {
  DETECTED: "DETECTED",
  AFTER_ACTION: "AFTER_ACTION",
  NO_ACTION: "NO_ACTION",
  CONFIRMED: "CONFIRMED"
} as const;

export const TEMPERATURE_TYPE = {
  MINIMUM: "MINIMUM",
  MAXIMUM: "MAXIMUM",
  AVERAGE: "AVERAGE",
  MINMAX: "MINMAX"
} as const;

export const DETECTION_CONDITION = {
  ABOVE: "ABOVE",
  BELOW: "BELOW",
  INCREASE: "INCREASE",
  DECREASE: "DECREASE"
} as const;

export const VEHICLE_TYPE = {
  CAR: "CAR",
  TRUCK: "TRUCK",
  BUS: "BUS",
  MOTORCYCLE: "MOTORCYCLE",
  BICYCLE: "BICYCLE"
} as const;

export const DEVICE_RULE_MODE = {
  LEFT_TO_RIGHT_IN: "LeftToRightIn",
  RIGHT_TO_LEFT_IN: "RightToLeftIn"
} as const;

export const CONTEXT_MENU_DASHBOARD = {
  NEW: "new",
  DUPLICATE: "duplicate",
  TEMPLATES: "templates"
} as const;

export const TIME_INTERVAL = {
  DAY_1: "1D",
  DAY_7: "7D",
  HOUR_1: "1H",
  HOUR_12: "12H",
  MIN_1: "1M",
  MIN_5: "5M",
  MIN_15: "15M",
  MIN_30: "30M"
} as const;

export const TYPE_TIME_INTERVAL = {
  HOUR: "HOUR",
  DAY: "DAY",
  WEEK: "WEEK"
} as const;

export const OVER_AVERAGE_CROWD_SAFETY = 500;

export const MAX_PIN_SET = 3;

export const OPTIONS_SETUP = {
  COMPARISON_DATE: "COMPARISON_DATE",
  ALARM: "ALARM",
  DURATION_TIME: "DURATION_TIME",
  LIST_VEHICLE: "LIST_VEHICLE",
  KPI: "KPI",
  RESET_TIME: "RESET_TIME"
} as const;

export const listMonth = [
  {
    value: MONTH.JAN,
    label: MONTH.JAN
  },
  {
    value: MONTH.FEB,
    label: MONTH.FEB
  },
  {
    value: MONTH.MAR,
    label: MONTH.MAR
  },
  {
    value: MONTH.APR,
    label: MONTH.APR
  },
  {
    value: MONTH.MAY,
    label: MONTH.MAY
  },
  {
    value: MONTH.JUN,
    label: MONTH.JUN
  },
  {
    value: MONTH.JUL,
    label: MONTH.JUL
  },
  {
    value: MONTH.AUG,
    label: MONTH.AUG
  },
  {
    value: MONTH.SEP,
    label: MONTH.SEP
  },
  {
    value: MONTH.OCT,
    label: MONTH.OCT
  },
  {
    value: MONTH.NOV,
    label: MONTH.NOV
  },
  {
    value: MONTH.DEC,
    label: MONTH.DEC
  }
];

export enum SHOW_COUNTS_TYPE {
  TOTAL,
  TRAFFIC
}

export const TRAFFIC_TYPE = {
  UP: "UP",
  DOWN: "DOWN",
  UP_DOWN: "UP_DOWN",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  LEFT_RIGHT: "LEFT_RIGHT"
} as const;

export enum OVERLAY_TYPE {
  AREA_TRAFFIC = "areaTraffic",
  PEOPLE_COUNTING = "peopleCounting",
  PASS_TRAFFIC = "passTraffic",
  EVENT = "event",
  CROWD_COUNTING = "crowdCounting"
}

export const PEOPLE_COUNT_MODE = {
  LEFT_TO_RIGHT_IN: "LeftToRightIn",
  RIGHT_TO_LEFT_IN: "RightToLeftIn"
} as const;
