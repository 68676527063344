import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { PageWrapper } from "@components/page-wrapper";
import { queryClient } from "@configs/Queries";
import { persistor, store } from "@configs/Redux";
import { getURL } from "@utils/Common";

import "@resources/css/Pretendard.css";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import reportWebVitals from "./reportWebVitals";

const main = async () => {
  /**
   * @fixme TEMP 검증 기간동안 production 모드에서 데이터 API는 사용함.
   */
  if (process.env.NODE_ENV.match(/(production)/g)) {
    const { worker } = await import("./mocks/browser");
    await worker.start();
  }

  if (
    process.env.NODE_ENV.match(/(development|test)/g) &&
    JSON.parse(process.env.REACT_APP_USED_MSW ?? "false")
  ) {
    const { worker } = await import("./mocks/browser");
    await worker.start({ onUnhandledRequest: "bypass" });
  }

  // TODO apollo v2 connect
  const httpLink = new HttpLink({
    uri: `https://api.${getURL()}`
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `wss://api.${getURL()}/graphql` || ""
    })
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wsLink as any,
    httpLink
  );

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only"
      },
      watchQuery: {
        fetchPolicy: "network-only"
      }
    }
  });

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <PageWrapper />
              </BrowserRouter>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
