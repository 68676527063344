import { GridBreakpointType, Union } from "@custom-types/Types";
import { FORMAT, GRID_BREAKPOINT_TYPE } from "@resources/Constants";
import { isNaN, isNil } from "lodash";
import moment from "moment";
import ReactGridLayout from "react-grid-layout";

export const classNames = (data: { [key: string]: boolean | undefined }) => {
  return Object.keys(data)
    .filter((key: string) => data[key])
    .join(" ");
};

export const channelLabeling = (
  value: string | number | boolean | undefined
): string => {
  return !isNil(value) && !isNaN(Number(value)) ? `${Number(value) + 1}` : "-";
};

export const convertDataTime = (
  timestamp: string | undefined,
  format: Union<typeof FORMAT>
): string => {
  return moment(new Date(Number(timestamp))).format(format);
};

export const convertHexToRgb = (hex: string, space = ",") => {
  const isValid = /^#([0-9a-fA-F]{3}){1,2}$/.test(hex);
  if (isValid) {
    const rgb = hex
      .replace(
        /^#([0-9a-fA-F]{2}|[0-9a-fA-F]{1})([0-9a-fA-F]{2}|[0-9a-fA-F]{1})([0-9a-fA-F]{2}|[0-9a-fA-F]{1})$/,
        "$1 $2 $3"
      )
      .split(" ");
    return rgb
      .map((item) => parseInt(`${item}${item.length === 1 ? item : ""}`, 16))
      .join(`${space} `);
  }
  return "";
};

export const snakeToCamel = (str: string): string => {
  const words = str.toLocaleLowerCase().split("_");
  const camelCase = words
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      const firstLetterCap = word.charAt(0).toUpperCase();
      const remainingLetters = word.slice(1);
      return firstLetterCap + remainingLetters;
    })
    .join("");

  return camelCase;
};

export const getNextLayout = (
  layouts: ReactGridLayout.Layouts,
  breakpoint: GridBreakpointType | null
): ReactGridLayout.Layout[] => {
  switch (breakpoint) {
    case GRID_BREAKPOINT_TYPE.XS:
      if (layouts[GRID_BREAKPOINT_TYPE.XS]?.length) {
        return layouts[GRID_BREAKPOINT_TYPE.XS];
      }
      return getNextLayout(layouts, GRID_BREAKPOINT_TYPE.SM);
    case GRID_BREAKPOINT_TYPE.SM:
      if (layouts[GRID_BREAKPOINT_TYPE.SM]?.length) {
        return layouts[GRID_BREAKPOINT_TYPE.SM];
      }
      return getNextLayout(layouts, GRID_BREAKPOINT_TYPE.MD);
    case GRID_BREAKPOINT_TYPE.MD:
      if (layouts[GRID_BREAKPOINT_TYPE.MD]?.length) {
        return layouts[GRID_BREAKPOINT_TYPE.MD];
      }
      return getNextLayout(layouts, GRID_BREAKPOINT_TYPE.LG);
    case GRID_BREAKPOINT_TYPE.LG:
      if (layouts[GRID_BREAKPOINT_TYPE.LG]?.length) {
        return layouts[GRID_BREAKPOINT_TYPE.LG];
      }
      return getNextLayout(layouts, GRID_BREAKPOINT_TYPE.XL);
    case GRID_BREAKPOINT_TYPE.XL:
      if (layouts[GRID_BREAKPOINT_TYPE.XL]?.length) {
        return layouts[GRID_BREAKPOINT_TYPE.XL];
      }
      return getNextLayout(layouts, GRID_BREAKPOINT_TYPE.XXL);
    default:
      return layouts[GRID_BREAKPOINT_TYPE.XXL];
  }
};
