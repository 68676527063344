import { CalendarModeRangeType, ColorMode, Lang } from "@custom-types/Types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  CALENDAR_MODE_RANGE_TYPE,
  FORMAT,
  LANG,
  THEME_TYPE
} from "@resources/Constants";
import moment from "moment";

export interface SystemState {
  colorMode: ColorMode;
  lang: Lang;
  dateFormat: string;
  datetimeFormat: string;
  dateTime: {
    mode: CalendarModeRangeType;
    start: string;
    end: string;
    comparisonStart: string;
    comparisonEnd: string;
  };
}

const today = moment().utc().toISOString(true);
const yesterday = moment().utc().subtract(1, "day").toISOString(true);

const initialState: SystemState = {
  colorMode: THEME_TYPE.LIGHT,
  lang: LANG.EN,
  dateFormat: FORMAT.DATE2,
  datetimeFormat: FORMAT.DATE_TIME_12H,
  dateTime: {
    mode: CALENDAR_MODE_RANGE_TYPE.TODAY,
    start: today,
    end: today,
    comparisonStart: yesterday,
    comparisonEnd: yesterday
  }
};

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    reset: () => {
      // Reset store
    },
    switchLang: (state, action: PayloadAction<Lang>) => {
      return {
        ...state,
        lang: action.payload
      };
    },
    updateDateTime: (state, action: PayloadAction<SystemState["dateTime"]>) => {
      return {
        ...state,
        dateTime: action.payload
      };
    },
    switchColorMode: (state, action: PayloadAction<ColorMode>) => {
      return {
        ...state,
        colorMode: action.payload
      };
    }
  }
});

export const { reset, switchLang, updateDateTime, switchColorMode } =
  systemSlice.actions;

export default systemSlice.reducer;
