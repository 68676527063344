const getHostName =
  typeof window === "undefined" || window.location.hostname === "localhost"
    ? "dev.sightmind.hanwhavision.cloud"
    : window.location.host.substring(window.location.host.indexOf("//") + 1);

const parseEnvironmentFromUrl = () => {
  const url = window.location.origin;
  if (url.includes("dev.")) {
    return "dev.";
  }
  if (url.includes("qa.")) {
    return "qa.";
  }
  return "";
};

const getPortalHostName =
  typeof window === "undefined" || window.location.hostname === "localhost"
    ? `dev.platform.hanwhavision.cloud`
    : `${parseEnvironmentFromUrl()}platform.hanwhavision.cloud`;

function getURL() {
  return getHostName;
}

function getPortalURL() {
  return getPortalHostName;
}

export { getURL, getPortalURL };
