import React from "react";
import { BarLoader } from "react-spinners";
import { LoaderSizeProps } from "react-spinners/helpers/props";

export const Loading = (props: LoaderSizeProps) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <BarLoader loading color="#f47320" height={2} {...props} />
    </div>
  );
};
