import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_DASHBOARD_SEQUENCE_CONVERSION_TIME } from "@resources/Constants";

export interface DashboardInfo {
  id: string;
  name: string;
}

interface DashboardState {
  dashboardActivated?: string;
  dashboardSequence: DashboardSequence;
  widgetSelected?: string;
  dashboards: DashboardInfo[];
}

export interface DashboardSequence {
  toggle: boolean;
  conversionTime: number;
  items: string[];
}

const initialState: DashboardState = {
  dashboardSequence: {
    toggle: false,
    conversionTime: DEFAULT_DASHBOARD_SEQUENCE_CONVERSION_TIME,
    items: []
  },
  dashboards: []
};

// TODO 삭제 후 redux로 옮길 예정
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    activeDashboard: (
      state,
      action: PayloadAction<DashboardState["dashboardActivated"]>
    ) => {
      return {
        ...state,
        dashboardActivated: action.payload
      };
    },
    showModalDeleteWidget: (
      state,
      action: PayloadAction<DashboardState["widgetSelected"]>
    ) => {
      return {
        ...state,
        widgetSelected: action.payload
      };
    },
    setDashboards: (state, action: PayloadAction<DashboardInfo[]>) => {
      return {
        ...state,
        dashboards: action.payload
      };
    },
    updateDashboardSequence: (
      state,
      action: PayloadAction<DashboardState["dashboardSequence"]>
    ) => {
      return {
        ...state,
        dashboardSequence: action.payload
      };
    }
  }
});

export const {
  activeDashboard,
  showModalDeleteWidget,
  updateDashboardSequence,
  setDashboards
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
