import styled from "styled-components";

export const PageWrapperStyled = styled.div`
  .page-wrapper {
    padding: 52px 0 0 64px;
    overflow: auto;
    height: 100vh;
  }

  .events-notification-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #0c0c0ccc;
    &:has(.wrapper) {
      display: block;
    }
    .events-notification-wrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 60px;
      justify-content: space-between;
      max-width: 1200px;
    }
  }
`;
