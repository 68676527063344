export const PALETTES = {
  DARK: {
    MONO_0: "#ffffff",
    MONO_1: "#cccfd1",
    MONO_2: "#959ba1",
    MONO_3: "#7c868f",
    MONO_4: "#56595b",
    MONO_5: "#444648",
    MONO_6: "#353739",
    MONO_7: "#313131",
    MONO_8: "#2b2b2b",
    MONO_9: "#252525",
    MONO_10: "#181818",
    MONO_BLACK: "#141414",
    TRANSPARENT: "transparent",

    ORANGE_1: "#fde3d3",
    ORANGE_2: "#fac7a6",
    ORANGE_3: "#f8ab7a",
    ORANGE_4: "#f58f4d",
    ORANGE_5: "#f37321",
    ORANGE_6: "#c25c1a",
    ORANGE_7: "#924514",
    ORANGE_8: "#612e0d",

    SYSTEM_BLUE: "#4891ff",
    SYSTEM_RED: "#fa4d4d",
    SYSTEM_GRAY: "#56595B",
    SYSTEM_GREEN: "#7dc519",
    SYSTEM_YELLOW: "#ffb400",
    SYSTEM_SAPPHIRE: "#5071f2",
    SYSTEM_ORANGE: "#ff6d10",
    SYSTEM_EMERALD: "#00b960",
    SYSTEM_CORAL: "#fd6e55",
    SYSTEM_INDIGO: "#5a44eb",
    SYSTEM_PURPLE: "#8333fc",
    SYSTEM_VIOLET: "#b93cf0",
    SYSTEM_PINK: "#ff5082"
  },
  LIGHT: {
    MONO_0: "#000000",
    MONO_1: "#111111",
    MONO_2: "#1e1e1e",
    MONO_3: "#2b2f33",
    MONO_4: "#3c3e40",
    MONO_5: "#4e5153",
    MONO_6: "#5d6165",
    MONO_7: "#747e87",
    MONO_8: "#c6ccce",
    MONO_9: "#e2e8ec",
    MONO_10: "#eef1f2",
    MONO_WHITE: "#ffffff",
    TRANSPARENT: "transparent",

    ORANGE_1: "#612e0d",
    ORANGE_2: "#924514",
    ORANGE_3: "#de671b",
    ORANGE_4: "#f37321",
    ORANGE_5: "#f58f4d",
    ORANGE_6: "#f8ab7a",
    ORANGE_7: "#fddbba",
    ORANGE_8: "#ffefe0",

    SYSTEM_BLUE: "#5596e2",
    SYSTEM_RED: "#f65a5a",
    SYSTEM_GRAY: "#747E87",
    SYSTEM_GREEN: "#8ec63f",
    SYSTEM_YELLOW: "#ffa400",
    SYSTEM_SAPPHIRE: "#556ed7",
    SYSTEM_ORANGE: "#f37321",
    SYSTEM_EMERALD: "#56ab50",
    SYSTEM_CORAL: "#ff816b",
    SYSTEM_INDIGO: "#647aed",
    SYSTEM_PURPLE: "#9a57e5",
    SYSTEM_VIOLET: "#895ae5",
    SYSTEM_PINK: "#ff598e"
  }
} as const;
