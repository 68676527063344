import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Loading } from "@components/common/loading";

const TimeLanguage = lazy(() => import("../pages/setup/system/time-language"));
const SystemManagement = lazy(
  () => import("../pages/setup/system/system-management")
);

const Dashboard = lazy(() => import("../pages/dashboard"));
const Event = lazy(() => import("../pages/event"));
const DeviceSetup = lazy(() => import("../pages/setup/device/device-setup"));
const Page403 = lazy(() => import("../pages/error-pages/page403"));
const Page404 = lazy(() => import("../pages/error-pages/page404"));
const Setup = lazy(() => import("src/pages/setup"));
const ProtectPage = lazy(() => import("@components/page-wrapper/ProtectPage"));
// const PublicPage = lazy(() => import("@components/page-wrapper/PublicPage"));

export const Router = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route element={<ProtectPage />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/event" element={<Event />} />
          <Route path="/setup" element={<Setup />}>
            <Route
              path="/setup/device/device-setup"
              element={<DeviceSetup />}
            />
            <Route
              path="/setup/system/system-management"
              element={<SystemManagement />}
            />
            <Route
              path="/setup/system/time-language"
              element={<TimeLanguage />}
            />
          </Route>
          <Route path="/forbidden" element={<Page403 />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
