import { ThemeType } from "@custom-types/Types";
import { THEME_TYPE } from "@resources/Constants";
import { PALETTES } from "@resources/Palettes";
import { Color, Theme } from "./Types";

const COLORS: {
  [THEME_TYPE.DARK]: Color;
  [THEME_TYPE.LIGHT]: Color;
} = {
  [THEME_TYPE.DARK]: {
    WHITE: PALETTES.DARK.MONO_0,
    BLACK: PALETTES.DARK.MONO_BLACK,

    /* SHADOW */
    SHADOW_SMALL: "0px 2px 4px rgba(0, 0, 0, 0.32)",
    SHADOW_MEDIUM: "8px 12px 20px rgba(0, 0, 0, 0.4)",
    SHADOW_LARGE: "20px 20px 36px rgba(0, 0, 0, 0.36)",

    /* MONO - 텍스트, 아이콘, 버튼 등에 사용되는 기본 컬러 */
    MONO_0: PALETTES.DARK.MONO_0,
    MONO_1: PALETTES.DARK.MONO_1,
    MONO_2: PALETTES.DARK.MONO_2,
    MONO_3: PALETTES.DARK.MONO_3,
    MONO_4: PALETTES.DARK.MONO_4,
    MONO_5: PALETTES.DARK.MONO_5,
    MONO_6: PALETTES.DARK.MONO_6,
    MONO_7: PALETTES.DARK.MONO_7,
    MONO_8: PALETTES.DARK.MONO_8,
    MONO_9: PALETTES.DARK.MONO_9,
    MONO_10: PALETTES.DARK.MONO_10,

    /* POINT - 한화 비전의 브랜드 컬러로, 포인트가 되는 요소에 사용 */
    ORANGE_1: PALETTES.DARK.ORANGE_1,
    ORANGE_2: PALETTES.DARK.ORANGE_2,
    ORANGE_3: PALETTES.DARK.ORANGE_3,
    ORANGE_4: PALETTES.DARK.ORANGE_4,
    ORANGE_5: PALETTES.DARK.ORANGE_5,
    ORANGE_6: PALETTES.DARK.ORANGE_6,
    ORANGE_7: PALETTES.DARK.ORANGE_7,
    ORANGE_8: PALETTES.DARK.ORANGE_8,
    ORANGE_PRIMARY: PALETTES.DARK.ORANGE_5,
    ORANGE_SECONDARY: PALETTES.DARK.ORANGE_7,
    ORANGE_TERTIARY: PALETTES.DARK.ORANGE_8,

    /* SYSTEM - 그래프 데이터에서 사용되는 컬러 */
    SYSTEM_BLUE: PALETTES.DARK.SYSTEM_BLUE,
    SYSTEM_RED: PALETTES.DARK.SYSTEM_RED,
    SYSTEM_MONO: PALETTES.DARK.MONO_4,
    SYSTEM_GREEN: PALETTES.DARK.SYSTEM_GREEN,
    SYSTEM_YELLOW: PALETTES.DARK.SYSTEM_YELLOW,
    SYSTEM_SAPPHIRE: PALETTES.DARK.SYSTEM_SAPPHIRE,
    SYSTEM_ORANGE: PALETTES.DARK.SYSTEM_ORANGE,
    SYSTEM_EMERALD: PALETTES.DARK.SYSTEM_EMERALD,
    SYSTEM_CORAL: PALETTES.DARK.SYSTEM_CORAL,
    SYSTEM_INDIGO: PALETTES.DARK.SYSTEM_INDIGO,
    SYSTEM_PURPLE: PALETTES.DARK.SYSTEM_PURPLE,
    SYSTEM_VIOLET: PALETTES.DARK.SYSTEM_VIOLET,
    SYSTEM_PINK: PALETTES.DARK.SYSTEM_PINK,
    SYSTEM_UPTREND: PALETTES.DARK.SYSTEM_BLUE,
    SYSTEM_DOWNTREND: PALETTES.DARK.SYSTEM_RED,
    SYSTEM_COMPARISON: PALETTES.DARK.MONO_4,

    /* LINE GRAPH */
    DATA_COLOR: PALETTES.DARK.MONO_4,
    GRID_LINE: PALETTES.DARK.MONO_6,
    SELECT_LINE: PALETTES.DARK.MONO_0,
    POINT_LINE: PALETTES.DARK.MONO_0,

    /* SURFACE */
    SURFACE: PALETTES.DARK.MONO_BLACK,
    SURFACE_WIDGET: "#1d1d1d",
    SURFACE_POPUP: PALETTES.DARK.MONO_8,
    SURFACE_MODAL: PALETTES.DARK.MONO_7,

    /* TEXT */
    TEXT_PRIMARY: PALETTES.DARK.MONO_0,
    TEXT_SECONDARY_1: PALETTES.DARK.MONO_1,
    TEXT_SECONDARY_2: PALETTES.DARK.MONO_3,
    TEXT_DISABLED: PALETTES.DARK.MONO_4,

    /* ICON */
    ICON_EMPHASIS: PALETTES.DARK.MONO_0,
    ICON_DEFAULT: PALETTES.DARK.MONO_1,
    ICON_DISABLED: PALETTES.DARK.MONO_4,

    /* BORDER */
    BORDER_LIGHT: PALETTES.DARK.MONO_7,
    BORDER_DEFAULT: PALETTES.DARK.MONO_6,
    BORDER_EMPHASIS: PALETTES.DARK.MONO_5,
    BORDER_TEXT: "rgba(0, 0, 0, 0.2)",

    /* CHART */
    COLOR_AXIS_LABEL: PALETTES.DARK.MONO_1
  },
  [THEME_TYPE.LIGHT]: {
    WHITE: PALETTES.LIGHT.MONO_WHITE,
    BLACK: PALETTES.LIGHT.MONO_0,

    /* SHADOW */
    SHADOW_SMALL: "0px 2px 2px rgba(0, 0, 0, 0.1)",
    SHADOW_MEDIUM: "8px 12px 20px rgba(0, 0, 0, 0.16)",
    SHADOW_LARGE: "20px 20px 36px rgba(0, 0, 0, 0.04)",

    /* MONO - 텍스트, 아이콘, 버튼 등에 사용되는 기본 컬러 */
    MONO_0: PALETTES.LIGHT.MONO_0,
    MONO_1: PALETTES.LIGHT.MONO_1,
    MONO_2: PALETTES.LIGHT.MONO_2,
    MONO_3: PALETTES.LIGHT.MONO_3,
    MONO_4: PALETTES.LIGHT.MONO_4,
    MONO_5: PALETTES.LIGHT.MONO_5,
    MONO_6: PALETTES.LIGHT.MONO_6,
    MONO_7: PALETTES.LIGHT.MONO_7,
    MONO_8: PALETTES.LIGHT.MONO_8,
    MONO_9: PALETTES.LIGHT.MONO_9,
    MONO_10: PALETTES.LIGHT.MONO_10,

    /* POINT - 한화 비전의 브랜드 컬러로, 포인트가 되는 요소에 사용 */
    ORANGE_1: PALETTES.LIGHT.ORANGE_1,
    ORANGE_2: PALETTES.LIGHT.ORANGE_2,
    ORANGE_3: PALETTES.LIGHT.ORANGE_3,
    ORANGE_4: PALETTES.LIGHT.ORANGE_4,
    ORANGE_5: PALETTES.LIGHT.ORANGE_5,
    ORANGE_6: PALETTES.LIGHT.ORANGE_6,
    ORANGE_7: PALETTES.LIGHT.ORANGE_7,
    ORANGE_8: PALETTES.LIGHT.ORANGE_8,
    ORANGE_PRIMARY: PALETTES.LIGHT.ORANGE_4,
    ORANGE_SECONDARY: PALETTES.LIGHT.ORANGE_7,
    ORANGE_TERTIARY: PALETTES.LIGHT.ORANGE_8,

    /* SYSTEM - 그래프 데이터에서 사용되는 컬러 */
    SYSTEM_BLUE: PALETTES.LIGHT.SYSTEM_BLUE,
    SYSTEM_RED: PALETTES.LIGHT.SYSTEM_RED,
    SYSTEM_MONO: PALETTES.LIGHT.MONO_7,
    SYSTEM_GREEN: PALETTES.LIGHT.SYSTEM_GREEN,
    SYSTEM_YELLOW: PALETTES.LIGHT.SYSTEM_YELLOW,
    SYSTEM_SAPPHIRE: PALETTES.LIGHT.SYSTEM_SAPPHIRE,
    SYSTEM_ORANGE: PALETTES.LIGHT.SYSTEM_ORANGE,
    SYSTEM_EMERALD: PALETTES.LIGHT.SYSTEM_EMERALD,
    SYSTEM_CORAL: PALETTES.LIGHT.SYSTEM_CORAL,
    SYSTEM_INDIGO: PALETTES.LIGHT.SYSTEM_INDIGO,
    SYSTEM_PURPLE: PALETTES.LIGHT.SYSTEM_PURPLE,
    SYSTEM_VIOLET: PALETTES.LIGHT.SYSTEM_VIOLET,
    SYSTEM_PINK: PALETTES.LIGHT.SYSTEM_PINK,
    SYSTEM_UPTREND: PALETTES.LIGHT.SYSTEM_BLUE,
    SYSTEM_DOWNTREND: PALETTES.LIGHT.SYSTEM_RED,
    SYSTEM_COMPARISON: PALETTES.LIGHT.MONO_7,

    /* LINE GRAPH */
    DATA_COLOR: PALETTES.LIGHT.MONO_8,
    GRID_LINE: PALETTES.LIGHT.MONO_9,
    SELECT_LINE: PALETTES.LIGHT.MONO_7,
    POINT_LINE: PALETTES.LIGHT.MONO_7,

    /* SURFACE */
    SURFACE: PALETTES.LIGHT.MONO_10,
    SURFACE_WIDGET: PALETTES.LIGHT.MONO_WHITE,
    SURFACE_POPUP: PALETTES.LIGHT.MONO_WHITE,
    SURFACE_MODAL: PALETTES.LIGHT.MONO_WHITE,

    /* TEXT */
    TEXT_PRIMARY: PALETTES.LIGHT.MONO_1,
    TEXT_SECONDARY_1: PALETTES.LIGHT.MONO_4,
    TEXT_SECONDARY_2: PALETTES.LIGHT.MONO_7,
    TEXT_DISABLED: PALETTES.LIGHT.MONO_8,

    /* ICON */
    ICON_EMPHASIS: PALETTES.LIGHT.MONO_1,
    ICON_DEFAULT: PALETTES.LIGHT.MONO_7,
    ICON_DISABLED: PALETTES.LIGHT.MONO_8,

    /* BORDER */
    BORDER_LIGHT: PALETTES.LIGHT.MONO_10,
    BORDER_DEFAULT: PALETTES.LIGHT.MONO_9,
    BORDER_EMPHASIS: PALETTES.LIGHT.MONO_8,
    BORDER_TEXT: "rgba(0, 0, 0, 0.1)",

    /* CHART */
    COLOR_AXIS_LABEL: PALETTES.DARK.MONO_7 
  }
} as const;

const theme = (
  themeType: ThemeType,
  colors: {
    [THEME_TYPE.DARK]: Color;
    [THEME_TYPE.LIGHT]: Color;
  }
): Theme => {
  const COLOR = colors[themeType];

  return {
    type: themeType,
    device: {
      mobile: "screen and (max-width: 395px)",
      tablet: "screen and (max-width: 768px)",
      desktop: "screen and (max-width: 1024px)"
    },
    event: {
      activeIcon: COLOR.ICON_EMPHASIS,
      textPrimary: COLOR.TEXT_PRIMARY,
      textSecondary: COLOR.TEXT_SECONDARY_1,
      paginationColor: COLOR.SELECT_LINE,
      backgroundColorBoard: COLOR.SURFACE_WIDGET,
      sidebarLineColor: COLOR.SURFACE,
      allTimeBackgroundColor: COLOR.SELECT_LINE,
      backgroundColorDefault: COLOR.BORDER_DEFAULT,
      backgroundColorSurface: COLOR.SURFACE,
      borderColorDisable: COLOR.BORDER_LIGHT,
      viewBackgroundColor: COLOR.SURFACE_MODAL,
      dateBackgroundColor: COLOR.SURFACE,
      todayBackgroundColor: COLOR.MONO_10,
      disableBackgroundColor: COLOR.MONO_10,
      pointPrimary: COLOR.ORANGE_PRIMARY,
      pointSecondary: COLOR.ORANGE_SECONDARY
    },
    widget: {
      heatmap: {
        sliderLineColor: COLOR.MONO_0
      },
      cumulatePeopleCounts: {
        textPrimary: COLOR.TEXT_PRIMARY,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        graphLineColor: COLOR.DATA_COLOR,
        graphBar: COLOR.TEXT_DISABLED,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        chartSelectLine: COLOR.SELECT_LINE
      },
      cumulateShoppingCartCounts: {
        textPrimary: COLOR.TEXT_PRIMARY,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        graphLineColor: COLOR.DATA_COLOR,
        graphBar: COLOR.TEXT_DISABLED,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        chartSelectLine: COLOR.SELECT_LINE
      },
      stayingPeopleCounts: {
        textPrimary: COLOR.TEXT_PRIMARY,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        graphLineColor: COLOR.DATA_COLOR,
        graphBar: COLOR.TEXT_DISABLED,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        chartSelectLine: COLOR.SELECT_LINE,
        textPrimaryColor: COLOR.TEXT_PRIMARY,
        textSecondary2Color: COLOR.TEXT_SECONDARY_2,
        lineGaugeChartColor: COLOR.SURFACE_WIDGET,
        graphPointLineColor: COLOR.POINT_LINE,
        chartBackgroundColor: COLOR.MONO_10,
        lineChartColor: COLOR.BORDER_DEFAULT,
        graphLineChartDefaultColor: COLOR.DATA_COLOR,
        progressChartBackgroundColor: COLOR.MONO_10,
        axisTickColor: COLOR.MONO_5,
        pointerColor: COLOR.MONO_9,
        pointerStrokeColor: COLOR.MONO_0,
        borderChartModal: COLOR.BORDER_LIGHT,
        progressBarColor: COLOR.BORDER_DEFAULT
      },
      countsByArea: {
        iconColorDisable: COLOR.ICON_DISABLED,
        iconColorEmphasis: COLOR.ICON_EMPHASIS,
        iconColorDefault: COLOR.ICON_DEFAULT,
        systemBlue: COLOR.SYSTEM_BLUE,
        systemRed: COLOR.SYSTEM_RED
      },
      entranceExitPeopleCounts: {
        textPrimary: COLOR.TEXT_PRIMARY,
        graphPointLine: COLOR.POINT_LINE,
        graphLineDefault: COLOR.DATA_COLOR,
        borderColorWidget: COLOR.BORDER_LIGHT,
        backgroundColorWidget: COLOR.SURFACE_WIDGET,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        white: COLOR.WHITE,
        chartSelectLine: COLOR.SELECT_LINE
      },
      rankPeopleCounts: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textSecondary: COLOR.TEXT_SECONDARY_1,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        borderModal: COLOR.BORDER_LIGHT,
        mono4: COLOR.MONO_4,
        graphDefaultLine: COLOR.DATA_COLOR,
        graphLineColor1: COLOR.SYSTEM_GREEN,
        graphLineColor2: COLOR.SYSTEM_YELLOW,
        graphLineColor3: COLOR.SYSTEM_SAPPHIRE,
        graphLineColor4: COLOR.SYSTEM_ORANGE,
        graphLineColor5: COLOR.SYSTEM_EMERALD,
        graphLineColor6: COLOR.SYSTEM_CORAL,
        graphLineColor7: COLOR.SYSTEM_INDIGO,
        graphLineColor8: COLOR.SYSTEM_PURPLE,
        graphLineColor9: COLOR.SYSTEM_VIOLET,
        graphLineColor10: COLOR.SYSTEM_PINK,
        white: COLOR.WHITE,
        borderLabel: COLOR.BORDER_TEXT,
        chartSelectLine: COLOR.SELECT_LINE
      },
      typePeopleCounts: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textSecondary: COLOR.TEXT_SECONDARY_1,
        graphLineColor: COLOR.DATA_COLOR,
        graphActive: COLOR.MONO_0,
        lineRadar: COLOR.BORDER_LIGHT,
        male: COLOR.SYSTEM_BLUE,
        female: COLOR.SYSTEM_RED,
        young: COLOR.SYSTEM_PINK,
        adult: COLOR.SYSTEM_ORANGE,
        middle: COLOR.SYSTEM_PURPLE,
        senior: COLOR.SYSTEM_SAPPHIRE,
        orange50: COLOR.ORANGE_5,
        orange60: COLOR.ORANGE_6,
        white: COLOR.WHITE,
        chartSelectLine: COLOR.SELECT_LINE,
        radarSplitArea: COLOR.SURFACE_WIDGET
      },
      cumulateSalesPos: {
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        graphLineColor: COLOR.DATA_COLOR,
        graphBar: COLOR.TEXT_DISABLED,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED,
        chartSelectLine: COLOR.SELECT_LINE
      },
      areaTraffic: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textSecondary: COLOR.TEXT_SECONDARY_1,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        graphLineColor: COLOR.DATA_COLOR,
        graphSymbol: COLOR.MONO_3,
        graphActive: COLOR.MONO_0,
        lineRadar: COLOR.BORDER_LIGHT,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        orange50: COLOR.ORANGE_5,
        orange60: COLOR.ORANGE_6,
        white: COLOR.WHITE
      },
      revenuePerSalesPos: {
        textPrimary: COLOR.TEXT_PRIMARY,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        graphLineColor: COLOR.DATA_COLOR,
        graphBar: COLOR.TEXT_DISABLED,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED
      },
      salesTransactionPos: {
        textPrimaryColor: COLOR.TEXT_PRIMARY,
        lineGaugeChartColor: COLOR.SURFACE_WIDGET,
        graphPointLineColor: COLOR.POINT_LINE,
        chartBackgroundColor: COLOR.MONO_10,
        lineChartColor: COLOR.BORDER_DEFAULT,
        graphLineChartDefaultColor: COLOR.DATA_COLOR,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        progressChartBackgroundColor: COLOR.MONO_10,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        chartSelectLine: COLOR.SELECT_LINE
      },
      checkoutQueue: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textLabel: COLOR.TEXT_SECONDARY_1,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        mono2: COLOR.MONO_2,
        mono8: COLOR.MONO_8,
        mono9: COLOR.MONO_9,
        mono10: COLOR.MONO_10,
        systemBlue: COLOR.SYSTEM_BLUE,
        systemRed: COLOR.SYSTEM_RED,
        systemGreen: COLOR.SYSTEM_GREEN,
        systemYellow: COLOR.SYSTEM_YELLOW,
        colorSplitLine: COLOR.BORDER_LIGHT
      },
      statusOfEntranceExit: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textSecondary: COLOR.TEXT_SECONDARY_1,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE
      },
      temperatureStatistics: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textSecondary2: COLOR.TEXT_SECONDARY_2,
        iconEmphasis: COLOR.ICON_EMPHASIS,
        backgroundColorChart: COLOR.MONO_10,
        backgroundColorBar: COLOR.MONO_8,
        colorDecrease: COLOR.SYSTEM_RED,
        colorIncrease: COLOR.SYSTEM_EMERALD,
        colorLabel: COLOR.TEXT_SECONDARY_1,
        colorLine: COLOR.BORDER_LIGHT,
        colorStroke: COLOR.SURFACE_WIDGET,
        chartSelectLine: COLOR.SELECT_LINE
      },
      stoppedVehicleStatistics: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textSecondary: COLOR.TEXT_SECONDARY_1,
        graphLineColor: COLOR.DATA_COLOR,
        graphActive: COLOR.MONO_0,
        lineRadar: COLOR.BORDER_LIGHT,
        today: COLOR.SYSTEM_BLUE,
        yesterday: COLOR.TEXT_DISABLED,
        car: COLOR.SYSTEM_EMERALD,
        bus: COLOR.SYSTEM_BLUE,
        truck: COLOR.SYSTEM_YELLOW,
        motorcycle: COLOR.SYSTEM_ORANGE,
        bicycle: COLOR.SYSTEM_PINK,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        graphBar: COLOR.TEXT_DISABLED,
        white: COLOR.WHITE,
        chartSelectLine: COLOR.SELECT_LINE,
        textLabelBarStack: COLOR.MONO_10,
        systemGreen: COLOR.SYSTEM_GREEN,
        radarSplitArea: COLOR.SURFACE_WIDGET
      },
      vehicleInOutStatistics: {
        textPrimary: COLOR.TEXT_PRIMARY,
        graphPointLine: COLOR.POINT_LINE,
        graphLineDefault: COLOR.DATA_COLOR,
        borderColorWidget: COLOR.BORDER_LIGHT,
        backgroundColorWidget: COLOR.SURFACE_WIDGET,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        white: COLOR.WHITE,
        chartSelectLine: COLOR.SELECT_LINE
      },
      crowdSafetyStatistics: {
        textPrimary: COLOR.TEXT_PRIMARY,
        textSecondary: COLOR.TEXT_SECONDARY_1,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        borderModal: COLOR.BORDER_LIGHT,
        mono4: COLOR.MONO_4,
        graphDefaultLine: COLOR.DATA_COLOR,
        graphLineColor1: COLOR.SYSTEM_YELLOW,
        graphLineColor2: COLOR.SYSTEM_GREEN,
        graphLineColor3: COLOR.SYSTEM_BLUE,
        graphLineColor4: COLOR.SYSTEM_ORANGE,
        graphLineColor5: COLOR.SYSTEM_EMERALD,
        graphLineColor6: COLOR.SYSTEM_INDIGO,
        graphLineColor7: COLOR.SYSTEM_PINK,
        graphLineColor8: COLOR.SYSTEM_VIOLET,
        white: COLOR.WHITE,
        borderLabel: COLOR.BORDER_TEXT,
        chartSelectLine: COLOR.SELECT_LINE
      },
      trafficJamStatistics: {
        textPrimary: COLOR.TEXT_PRIMARY,
        backgroundColorBar: COLOR.MONO_8,
        colorTimeline: COLOR.SYSTEM_RED,
        colorLabel: COLOR.TEXT_SECONDARY_1,
        colorLine: COLOR.BORDER_LIGHT,
        colorStroke: COLOR.SURFACE_WIDGET
      },
      cumulateVehicleCounts: {
        textPrimary: COLOR.TEXT_PRIMARY,
        backgroundColorTooltip: COLOR.SURFACE_WIDGET,
        borderColorTooltip: COLOR.BORDER_LIGHT,
        graphLineColor: COLOR.DATA_COLOR,
        graphBar: COLOR.TEXT_DISABLED,
        graphBarBlue1: COLOR.SYSTEM_BLUE,
        graphBarRed1: COLOR.SYSTEM_RED,
        systemRed: COLOR.SYSTEM_RED,
        systemBlue: COLOR.SYSTEM_BLUE,
        chartSelectLine: COLOR.SELECT_LINE,
        white: COLOR.WHITE
      }
    },
    chart: {
      colorAxisLabel: COLOR.COLOR_AXIS_LABEL
    }
  };
};

export const getTheme = (themeType: ThemeType) => {
  return {
    ...theme(themeType, COLORS)
  };
};
