import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type WidgetState = {
  ratio: {
    [key: string]: number;
  };
};

const initialState: WidgetState = {
  ratio: {}
};

// TODO Save the ratio on the server as a config.
export const widgetSlice = createSlice({
  name: "widget",
  initialState,
  reducers: {
    updateMainProportion: (
      state: WidgetState,
      action: PayloadAction<WidgetState["ratio"]>
    ): WidgetState => {
      return {
        ...state,
        ratio: { ...state.ratio, ...action.payload }
      };
    }
  }
});

export const { updateMainProportion } = widgetSlice.actions;

export default widgetSlice.reducer;
